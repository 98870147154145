/**
 * This file was automatically generated by Strapi.
 * Any modifications made will be discarded.
 */
import ckeditor from "/node_modules/.strapi/vite/deps/@ckeditor_strapi-plugin-ckeditor_strapi-admin.js?v=62cc2f62";
import strapiCloud from "/node_modules/.strapi/vite/deps/@strapi_plugin-cloud_strapi-admin.js?v=b7c7dbba";
import usersPermissions from "/node_modules/.strapi/vite/deps/@strapi_plugin-users-permissions_strapi-admin.js?v=49a4a021";
import { renderAdmin } from "/node_modules/.strapi/vite/deps/@strapi_strapi_admin.js?v=11e3ff8c";

renderAdmin(document.getElementById("strapi"), {
  plugins: {
    ckeditor: ckeditor,
    "strapi-cloud": strapiCloud,
    "users-permissions": usersPermissions,
  },
});
